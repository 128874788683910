<sb-dialog-header class="grid grid-cols-1 children:col-start-1 children:row-start-1">
  <div
    class="aspect-8/3 items-center justify-center overflow-hidden rounded-lg bg-grey-200/25 bg-cover bg-center bg-no-repeat"
    [ngClass]="data.image.classNames"
    [ngStyle]="{
      'background-image': data.image.src ? 'url(' + data.image.src + ')' : 'none'
    }"
  ></div>
</sb-dialog-header>

<sb-dialog-content>
  @if (data.badge) {
    <sb-badge [color]="'blue'" class="mb-2">{{ data.badge }}</sb-badge>
  }
  <h3 sb-dialog-title class="mb-4">{{ data.title }}</h3>
  @if (data.contentTemplate) {
    <ng-container *ngTemplateOutlet="data.contentTemplate"></ng-container>
  } @else if (data.description) {
    <p class="mb-3" [innerHTML]="data.description"></p>
  }
</sb-dialog-content>

<sb-dialog-footer>
  @if (data.secondary) {
    <sb-dialog-footer-actions [align]="'start'">
      <a sb-button [color]="'transparent'" target="_blank" [attr.href]="data.secondary.href">
        {{ data.secondary.text || ('Learn more' | translate) }}
      </a>
    </sb-dialog-footer-actions>
  }

  <sb-dialog-footer-actions [align]="'end'">
    <button sb-button [color]="'primary'" (click)="data.primary?.onClick?.(); onPrimaryAction()">
      {{ data.primary?.text || ('Okay' | translate) }}
    </button>
  </sb-dialog-footer-actions>
</sb-dialog-footer>
