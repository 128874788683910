import { FocusableOption } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostBinding, inject, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbTabLabel], [sb-tab-label], sb-tab-label',
  exportAs: 'sbTabLabel',
})
export class TabLabelDirective implements FocusableOption {
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  @HostBinding('attr.aria-disabled')
  get ariaDisabled(): boolean | null {
    return this.disabled || null;
  }

  @Input()
  disabled?: boolean | undefined;

  focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
