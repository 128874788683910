import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbSurface]',
  standalone: true,
})
export class SurfaceDirective {
  @HostBinding('class')
  classList = clsx('rounded-2xl bg-grey-100 p-4 text-grey-500');
}

@Directive({
  selector: '[sbSurfaceTitle]',
  standalone: true,
})
export class SurfaceTitleDirective {
  @HostBinding('class')
  classList = clsx('text-sm font-semibold text-grey-500');
}
