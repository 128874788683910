<div class="sb-tab-nav-bar">
  <div class="sb-tab-nav-bar-list" role="tablist" (keydown)="onKeyDown($event)">
    @for (tab of tabs; track tab.id; let index = $index) {
      <div
        role="tab"
        class="sb-tab-nav-bar-list-link"
        sbTabLabel
        [disabled]="tab.disabled"
        [id]="tab.tabLabelId"
        (click)="tab.disabled ? null : selectTabByIndex(index)"
        [attr.tabIndex]="index === selectedIndex ? 0 : -1"
        [attr.aria-selected]="selectedIndex === index"
        [attr.aria-controls]="tab.tabContentId"
      >
        {{ tab.title }}
      </div>
    }
  </div>
</div>
<div class="sb-tab-body" cdkScrollable>
  @for (tab of tabs; track tab.id; let index = $index) {
    <div
      role="tabpanel"
      class="sb-tab-body-content"
      [id]="tab.tabContentId"
      [attr.aria-hidden]="selectedIndex !== index"
      [attr.aria-labelledby]="tab.tabLabelId"
    >
      @if (selectedIndex === index) {
        <ng-template [ngTemplateOutlet]="tab.template"></ng-template>
      }
    </div>
  }
</div>
