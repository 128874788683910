import { NgModule } from '@angular/core';

import { SurfaceDirective, SurfaceTitleDirective } from './surface';

const standalone = [SurfaceDirective, SurfaceTitleDirective];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class SbSurfaceModule {}
