@if (hasAvatar) {
  <img class="size-full object-cover" [src]="url" alt="avatar" (error)="onFetchError()" />
} @else {
  <div
    class="flex size-full select-none items-center justify-center font-semibold"
    [style.color]="avatarColor"
    [style.background-color]="avatarBackgroundColor"
  >
    {{ initials }}
  </div>
}
