import { booleanAttribute, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

let index = 0;

@Component({
  selector: 'sb-tab',
  standalone: true,
  exportAs: 'sbTab',
  template: '<ng-template><ng-content></ng-content></ng-template>',
})
export class TabComponent {
  @Input({ required: true })
  title!: string;

  @Input({ transform: booleanAttribute })
  disabled = false;

  @ViewChild(TemplateRef, { static: true })
  template!: TemplateRef<HTMLElement>;

  @Output()
  selected = new EventEmitter<void>();

  @Input()
  set id(value: string) {
    if (!value || value === this._id) {
      return;
    }
    this._id = value;
  }
  get id(): string {
    return this._id;
  }

  private _id = `sb-tab-${index++}`;

  get tabLabelId(): string {
    return `${this.id}-label`;
  }

  get tabContentId(): string {
    return `${this.id}-content`;
  }
}
