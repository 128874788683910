<!-- eslint-disable tailwindcss/no-arbitrary-value -->
<sb-popover [maxWidth]="'23rem'">
  <div class="flex items-center gap-3">
    <sb-avatar [size]="'lg'" [url]="avatarUrl" [firstName]="firstName" [lastName]="lastName" [colorSeed]="id" />
    <div class="flex flex-col gap-3 overflow-hidden">
      <div class="flex gap-1.5 text-2xl font-bold leading-5">
        <span class="truncate">
          @if (firstName) {
            {{ firstName }}
          }
          @if (lastName) {
            {{ lastName }}
          }
        </span>
      </div>
      <div class="flex flex-wrap gap-1.5 truncate">
        @if (locationLabel) {
          <sb-badge [color]="'grey'">
            <span class="truncate">{{ locationLabel }}</span>
          </sb-badge>
        }
        @if (team) {
          <sb-badge [color]="'grey'">
            <span class="truncate">{{ team }}</span>
          </sb-badge>
        }
      </div>
    </div>
  </div>
  <!-- TODO replace with bg-medium once tokens are in place -->
  <div class="flex flex-col gap-3 overflow-hidden rounded-md bg-[#F1F5F9] p-4">
    @for (item of items; track item.icon) {
      <div class="flex w-full items-center font-medium">
        <sb-icon class="mr-2 shrink-0" [name]="item.icon" [size]="'lg'" />
        <span class="truncate">{{ item.label }}</span>
      </div>
    }
  </div>
  <sb-popover-footer>
    <button sb-button [color]="'secondary'" (click)="viewProfile.emit()">
      {{ 'View profile' | translate }}
    </button>
  </sb-popover-footer>
</sb-popover>
