import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getSeededDefaultColor, lightenHex } from '@shiftbase-com/utilities';
import { clsx } from 'clsx';

// TODO if we ever need more avatar sizes, keep them in sync with IconSize in icon.component.ts
export enum AvatarSize {
  'sm' = 'sm',
  'base' = 'base',
  'lg' = 'lg',
}

export type avatarSize = keyof typeof AvatarSize | undefined;

@Component({
  selector: 'sb-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnChanges {
  public failedAvatarFetch = false;
  public avatarColor = '';
  public avatarBackgroundColor = '';

  private readonly sizeClasses = {
    [AvatarSize.sm]: clsx('size-6 text-xs'), // 24px - avatar-badge group
    [AvatarSize.base]: clsx('size-7 text-sm'), // 28px - employee select
    [AvatarSize.lg]: clsx('size-16 text-4xl'), // 64px - employee popover
  };

  @Input()
  public url?: string;

  @Input()
  set size(value: avatarSize) {
    if (value === this.size) {
      return;
    }
    this._size = value;
    this.classes = this.hostClasses;
  }
  get size(): avatarSize {
    return this._size;
  }
  _size?: avatarSize;

  @Input()
  public firstName?: string;

  @Input()
  public lastName?: string;

  // TODO this could just as well be determined based on f.e. the concatenation of firstName and lastName as it is just acting as a seed, but that would change the colors that people might be used to seeing.
  // UX insisted on not picking different colors: https://differentlab.atlassian.net/browse/SHB-9640?focusedCommentId=36670. So for now we'll have to maintain this colorSeed.
  @Input()
  public colorSeed?: string;

  @HostBinding('class')
  public classes: string;

  public constructor() {
    this.size = AvatarSize.base;
    this.classes = this.hostClasses;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['colorSeed'] && changes['colorSeed'].currentValue !== changes['colorSeed'].previousValue) {
      this.avatarColor = getSeededDefaultColor(this.colorSeed ?? '');
      this.avatarBackgroundColor = lightenHex(this.avatarColor);
    }
  }

  public onFetchError() {
    this.failedAvatarFetch = true;
  }

  public get hasAvatar() {
    if (this.failedAvatarFetch) {
      return false;
    }
    return !!this.url;
  }

  public get initials(): string {
    const firstLetter = this.firstName ? this.firstName.charAt(0).toUpperCase() : '';
    const secondLetter = this.lastName ? this.lastName.charAt(0).toUpperCase() : '';
    return `${firstLetter}${secondLetter}`;
  }

  private get hostClasses(): string {
    return clsx(['flex flex-none overflow-hidden rounded-full'], this.size ? this.sizeClasses[this.size] : '');
  }
}
