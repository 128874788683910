import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sb-legacy-dialog-block',
  standalone: true,
  templateUrl: './dialog-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBlockComponent {
  @Input()
  public header: string | undefined;

  @HostBinding('class')
  public class = 'block';
}
